























































/* eslint-disable */
import { Vue, Component, Watch } from 'vue-property-decorator';
import { More } from '@/mixin/more';
import { OtherModule } from '@/store/other';
import { api_user } from '@/api';
import { numFormat } from '@/util/string';
@Component
export default class extends More(api_user.get_pay) {
  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
  numFormat = numFormat;
}
